import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import supabase from './supabaseClient';
import TopArtists from './TopArtists';
import Header from './Header';
import theme from './theme';
import ArtistTourDates from './ArtistTourDates';
import AccountProfile from './AccountProfile';
import TicketPurchase from './TicketPurchase';
import './App.css';
import spotifyLogo from './assets/spotify-logo.svg';
import About from './About';
import MeetTheTeam from './MeetTheTeam';
import LoginPage from './LoginPage'; // Import the LoginPage component

// Load your Stripe publishable key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Main />
      </Router>
    </ThemeProvider>
  );
}

function Main() {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      if (!session) {
        navigate('/');
      }
    });

    return () => subscription?.unsubscribe();
  }, [navigate]);

const signInWithSpotify = async () => {
  try {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'spotify',
      options: {
        scopes: 'user-top-read',
        redirectTo: window.location.origin  // Dynamically uses the current domain
      },
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Error signing in with Spotify:', error);
  }
};


  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
    }
    navigate('/');
  };

  return (
    
    <div className="App" style={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
      <Header onSignOut={signOut} />
      <Elements stripe={stripePromise}>
        <Routes>
          <Route
            path="/"
            element={
              !session ? (
                <LoginPage onLogin={signInWithSpotify} />
              ) : (
                <div className="logged-in">
                  <TopArtists />
                </div>
              )
            }
          />
        
          <Route path="/artist/:artistId/:ticketmasterId" element={<ArtistTourDates />} />
          <Route path="/account-profile" element={<AccountProfile />} />
          <Route path="/ticket-purchase/:eventId" element={<TicketPurchase />} />
          <Route path="/about" element={<About />} />
          <Route path="/meet-the-team" element={<MeetTheTeam />} />
        </Routes>
      </Elements>
    </div>
  );
}

export default App;
