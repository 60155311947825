import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

const teamMembers = [
  {
    name: 'Grant Rataski',
    role: 'CEO',
    image: 'https://via.placeholder.com/150',
    bio: 'Grant is the visionary behind Fan Fidel, wanting to create a fair system for concert lover',
  },
  // Add more team members as needed
];

const MeetTheTeam = () => {
  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Meet the Team
      </Typography>
      <Grid container spacing={4}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                alt={member.name}
                height="140"
                image={member.image}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {member.name}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {member.role}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {member.bio}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MeetTheTeam;
